import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ReactComponent as ChatSendDisabled } from 'src/assets/svg/chat-send-disabled.svg';
import { ReactComponent as ChatSendEnabled } from 'src/assets/svg/chat-send-enabled.svg';
import { ChatSetting } from 'src/components/chat/ChatSetting';
import { DateMessage } from 'src/components/chat/DateMessage';
import { ReceiveMessage } from 'src/components/chat/ReceiveMessage';
import { SendMessage } from 'src/components/chat/SendMessage';
import { SystemMessage } from 'src/components/chat/SystemMessage';
import { BackButton, TopNavbar } from 'src/components/common';
import { Icon } from 'src/components/common/icons';
import { useChatRoomInfo } from 'src/container/chat-room-info';
import { useStudentChatMessageList } from 'src/container/student-chat-message-list';
import { Chat } from 'src/generated/model';
import { useSocket } from 'src/lib/socket';
import { meState } from 'src/store';
import { isNowOrFuture } from 'src/util/time';

interface ChatDetailPageProps {
  id: string;
}

export function ChatDetailPage({ id }: ChatDetailPageProps) {
  id = id.split('/')[0];

  const me = useRecoilValue(meState);

  const [isSettingOpen, setSettingOpen] = useState(false);
  const [isSettingAttendee, setSettingAttendee] = useState(false);

  const { chatRoomInfo, chatRoomTitle, chatRoomAttendees, chatReadInfo } = useChatRoomInfo(Number(id));

  //const [lastSender, setLastSender] = useState(0);

  const {
    newMessage,
    setNewMessage,
    chatMessages,
    createNewMessage,
    isLoadingNewMessage,
    refetch: refetchChatMessages,
  } = useStudentChatMessageList(Number(id));

  const [socket, disconnect] = useSocket(`chat-${id}`);

  const [myReadTime, setMyReadTime] = useState('');
  const [chatCloseMsg, setChatCloseMsg] = useState('');
  const [chatOpenTime, setChatOpenTime] = useState(true);

  useEffect(() => {
    setMyReadTime('');
  }, [id]);

  useEffect(() => {
    if (chatRoomInfo?.roomData.chatStartTime && chatRoomInfo?.roomData.chatEndTime) {
      if (
        (!isNowOrFuture(chatRoomInfo?.roomData.chatStartTime) && isNowOrFuture(chatRoomInfo?.roomData.chatEndTime)) ||
        chatRoomInfo?.roomData.chatStartTime === chatRoomInfo?.roomData.chatEndTime
      ) {
        if (chatRoomInfo?.roomData.chatStartTime === chatRoomInfo?.roomData.chatEndTime) {
          setChatOpenTime(false);
          setChatCloseMsg('상대방이 대화가 불가능한 상태입니다.');
        } else {
          setChatOpenTime(true);
        }
        setNewMessage('');
      } else {
        setChatOpenTime(false);
        setNewMessage('');
        setChatCloseMsg(
          '대화 가능 시간은 ' +
            chatRoomInfo?.roomData.chatStartTime +
            '~' +
            chatRoomInfo?.roomData.chatEndTime +
            '입니다.',
        );
      }
    }
  }, [chatRoomInfo]);

  useEffect(() => {
    if (me && chatReadInfo && chatMessages) {
      if (!myReadTime) {
        setMyReadTime(chatReadInfo?.find((item) => item.attendeeId === me.id)?.updatedAt || '');
      }

      chatMessages?.items
        ?.slice()
        ?.sort((a: Chat, b: Chat) => (a.createdAt > b.createdAt ? -1 : 1))
        .map((item, i) => {
          if (i > 5 && item.createdAt > myReadTime) {
            setMyReadTime(item.createdAt);
          }
        });
    }
  }, [me, chatReadInfo, chatMessages]);

  useEffect(() => {
    if (id && socket) {
      socket?.emit('in', { id: Number(me?.id) });
    }

    socket?.on('chat', (chatdata: Chat) => {
      console.log('chat', chatdata);
      refetchChatMessages()
        .then(() => {
          //
        })
        .catch((error) => {
          //alert(error?.message);
        });
    });

    // return () => {
    //   socket?.off('onlineList');
    // };
  }, [id, me?.id, socket]);

  useEffect(() => {
    return () => {
      disconnect();
    };
  }, [id, disconnect]);

  // 메시지 입력
  const OnSendMessage = () => {
    if (!isLoadingNewMessage) {
      createNewMessage();
    }
  };

  // 채팅이 업데이트될 때마다 아래로 스크롤
  const chatListRef = useRef<HTMLImageElement | null>(null);
  const chatReadRef = useRef<HTMLImageElement | null>(null);
  useEffect(() => {
    chatListRef.current?.scrollTo(0, chatListRef.current.scrollHeight);
  }, [chatMessages, myReadTime]);

  return (
    <>
      <div className=" relative bg-gray-50">
        <div className="block ">
          <TopNavbar
            title={chatRoomTitle}
            left={<BackButton className="h-15" />}
            right={
              <div
                onClick={() => {
                  setSettingAttendee(true);
                  setSettingOpen(true);
                }}
                className="flex h-15 w-10 items-center "
              >
                <Icon.MoreHorizontal />
              </div>
            }
          />
        </div>

        <div className="h-screen-14  bg-gray-200">
          <div className="h-full overflow-y-auto p-2" ref={chatListRef}>
            {chatMessages?.items?.map((c: Chat, idx: number) => (
              <>
                <DateMessage key={idx} PreMessageData={chatMessages?.items[idx - 1]} MessageData={c} />

                {c.type === 'SYSTEM' ? (
                  <SystemMessage key={c.id} MessageData={c} />
                ) : c.senderId === me?.id ? (
                  <SendMessage
                    key={c.id}
                    PreMessageData={chatMessages?.items[idx - 1]}
                    MessageData={c}
                    PostMessageData={chatMessages?.items[idx + 1]}
                    userRole="student"
                  />
                ) : (
                  <ReceiveMessage
                    key={c.id}
                    PreMessageData={chatMessages?.items[idx - 1]}
                    MessageData={c}
                    PostMessageData={chatMessages?.items[idx + 1]}
                    AttendeeInfo={chatRoomAttendees?.find((item) => item.id === c.senderId)}
                    userRole="student"
                  />
                )}
              </>
            ))}
          </div>
        </div>

        <div className="flex-2 px-3 py-3">
          <div className="write flex  rounded-2xl border-2 bg-white">
            <div className="flex-1">
              <textarea
                name="message"
                placeholder={chatOpenTime ? '메시지를 입력하세요.' : chatCloseMsg}
                disabled={!chatOpenTime || isLoadingNewMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                value={newMessage}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) OnSendMessage();
                }}
                className="block w-full border-none bg-transparent px-2 py-2 outline-none"
              />
            </div>
            <div className="flex content-center items-center p-2">
              {newMessage && chatOpenTime ? <ChatSendEnabled onClick={() => OnSendMessage()} /> : <ChatSendDisabled />}
            </div>
          </div>
        </div>
      </div>

      <ChatSetting
        modalOpen={isSettingOpen}
        showAttendees={isSettingAttendee}
        setUpdateInfo={() => setSettingOpen(false)}
        removeUser={() => setSettingOpen(false)}
        addUser={() => setSettingOpen(false)}
        setModalClose={() => setSettingOpen(false)}
        setCloseChat={() => setSettingOpen(false)}
        onSearchUser={() => setSettingOpen(false)}
        searchUserList={undefined}
        info={chatRoomInfo}
      />
    </>
  );
}
